<template>
  <div class="mainpage">
    <!-- 开放实验 -->
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="searchitem">
            <div class="maintext">学&nbsp;&nbsp;&nbsp;期:</div>
            <el-select class="elinput" v-model="queryParams.semester_id" placeholder="请选择">
              <el-option
                v-for="item in semesters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="searchitem">
            <div class="maintext">学&nbsp;&nbsp;&nbsp;院:</div>
            <el-select class="elinput" v-model="queryParams.college_id" placeholder="请选择">
              <el-option
                v-for="item in optionthree"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="searchitem">
            <div class="maintext">实验室:</div>
            <el-select
              class="elinput"
              v-model="queryParams.lab_id"
              filterable
              placeholder="请选择"
              v-selectScroll="handleScroll"
            >
              <el-option
                v-for="item in optionfour"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="searchitem">
            <div class="maintext">起始周:</div>
            <el-select
              class="elinput"
              v-model="queryParams.start_week"
              placeholder="请选择"
              size="medium "
            >
              <el-option
                v-for="item in Number(week_numbers)"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="searchitem">
            <div class="maintext">截止周:</div>
            <el-select
              class="elinput"
              v-model="queryParams.end_week"
              placeholder="请选择"
              size="medium "
            >
              <el-option
                v-for="item in Number(week_numbers)"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="searchitem">
            <div class="maintext">关键字:</div>
            <el-input class="elinput" v-model="queryParams.keyword" placeholder="请输入课程名/项目名/实验用房"></el-input>
          </div>
        </div>
        <div class="right">
          <!-- <el-button type="primary" @click="searchbt">查询</el-button>
          <el-button type="info" @click="resetbt">重置</el-button>-->
          <div class="bt searchbt" @click="searchbt">查询</div>
          <div class="bt restbt" @click="resetbt">重置</div>
        </div>
      </div>
      <div class="main-contain">
        <div class="contain">
          <el-table
            ref="singleTable"
            class="table"
            :data="tableData"
            border
            highlight-current-row
            stripe
            :header-cell-style="{ 'font-size': '15px', 'color': '#666666', 'font-weight': 'bold', 'background': '#FFFFFF' }"
            :row-style="{ 'font-size': '15px', 'color': '#222222', 'font-weight': '400' }"
          >
            <el-table-column prop="experiment_list" label="项目名称">
              <template slot-scope="scope">
                <p v-for="(o, i) in scope.row.experiment_list" :key="i">
                  <span>{{ o.experiment_name }}</span>
                  <span>（{{ o.is_must_select }}）</span>
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="course_lib_name" label="所属课程" />
            <el-table-column prop="college_name" label="学院" />
            <el-table-column prop="lab_name" label="实验室" />
            <el-table-column prop="lab_room_name" label="实验用房" />
            <el-table-column prop="weekly_list" label="开放周次">
              <template slot-scope="scope">{{ scope.row.weekly_list }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" min-width="100" v-if="token">
              <template slot-scope="scope">
                <div class="operatcontain">
                  <el-button
                    class="opreatbt"
                    v-if="scope.row.is_can_book == 1"
                    @click="opreatbt(scope.row)"
                  >预约</el-button>
                  <el-button class="opreatbt" v-else @click="opreatlogbt(scope.row)">查看预约记录</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="queryParams.page"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
    <Customdialog
      ref="customdialog"
      class="customdialog"
      width="60%"
      type="table"
      title="开放实验预约"
      :showclose="true"
    >
      <div slot="dialogbody">
        <Table ref="mytable" :closebt="closedialog" @opreated="getOpenExperimentList"></Table>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue"
import Table from "./table"
import { getToken, getIdentifierToken } from "@/utils/auth"
import { getlablist } from "@/api/lab"
import { gettreelist } from "@/api/common/semester"
import { getcollegelist } from "@/api/college"
import { getopenexperimentlabroomlist } from "@/api/openexperiment"
export default {
  name: "mainpage",
  components: {
    Customdialog,
    Table,
  },
  data () {
    return {
      token: getToken() ? true : false,
      tableData: [],
      total: 0,
      queryParams: {
        semester_id: "",
        college_id: "",
        start_week: "",
        end_week: "",
        keyword: "",
        lab_id: '',
        page: 1,
        per_page: 10
      },
      semesters: [], // 学期列表
      optionthree: [],
      optionfour: [],
      week_numbers: '',
      hasMoreItems: true, // 是否还有更多选项
    }
  },
  computed: {},
  created () {
    this.getSemester() // 下拉数据-学期
    this.getcollegelist()
    this.getlablist()
  },
  methods: {
    handleScroll () {
      if (this.hasMoreItems) {
        // 增加当前页码
        this.queryParams.page++

        // 调用分页接口，传递搜索关键字和分页参数
        this.getlablist()
      }
    },

    // 获取开放实验实验用房列表
    getOpenExperimentList () {
      getopenexperimentlabroomlist(this.queryParams)
        .then((response) => {
          this.tableData = response.data.data
          this.total = response.data.total
        })
        .catch((error) => { })
    },
    // 切换页面
    changePage (val) {
      this.queryParams.page = val
      this.getOpenExperimentList()
    },

    closedialog () {
      this.$refs.customdialog.dialogclosebt()
      // location.reload()
    },
    todetail () {
      this.$router.push({
        path: "/openexperiment/experimentdetails",
      })
    },
    opreatbt (item) {
      if (this.token) {
        if (getIdentifierToken() == 3) {
          this.$confirm("该功能仅对校内人员开放!", "提示", {
            confirmButtonText: "知道了",
            showCancelButton: false,
            type: "warning",
          })
            .then(() => { })
            .catch(() => { })
        } else if (getIdentifierToken() == 4) {
          this.$confirm("该功能未对您开放,请联系管理员!", "提示", {
            confirmButtonText: "知道了",
            showCancelButton: false,
            type: "warning",
          })
        } else {
          // 判断是否是自拟项目
          if (item.experiment_type == 0) {
            this.$refs.customdialog.dialogopenbt()
            this.$nextTick(() => {
              this.$refs.mytable.inidata(item, this.week_numbers, 0)
            })
          } else if (item.experiment_type == 1 || item.experiment_type == 2) {
            this.$refs.customdialog.dialogopenbt()
            this.$nextTick(() => {
              this.$refs.mytable.inidata(item, this.week_numbers, 1)
            })
          }
        }
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push({
            path: "/login",
          })
        }).catch(() => { })
      }
    },
    opreatlogbt (row) {
      if (this.token) {
        let mypage = {
          active: 4,
          componentId: "Openexperiment",
        }
        sessionStorage.setItem('my-page', JSON.stringify(mypage))
        this.$router.push({
          path: "/home/personalcenter/mainpage",
        })
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push({
            path: "/login",
          })
        }).catch(() => { })
      }
    },
    searchbt () {
      this.queryParams.page = 1
      this.getOpenExperimentList()
    },
    // change(data) {
    //   debugger
    //   this.queryParams.semester_id = data
    //   this.week_numbers = this.semesters.find(el => el.id == data).week_number
    // },
    resetbt () {
      this.queryParams.college_id = ""
      this.queryParams.semester_id = ""
      this.queryParams.start_week = ""
      this.queryParams.end_week = ""
      this.queryParams.keyword = ""
      this.queryParams.lab_id = ""
      this.getOpenExperimentList()
    },
    formatSelect (data) {
      let _data = []
      // 时间排序
      _data = data.sort((a, b) => {
        return a.start_date - b.start_date
      })
      // 获取学期列表
      this.semesters = _data.map(el => {
        if (el.is_current == 1) {
          this.queryParams.semester_id = el.id
          this.week_numbers = el.week_number
          this.getOpenExperimentList()
        }
        let year = el.start_year + "-" + el.end_year + "学年"
        let nper = el.nper === 1 ? "第一学期" : "第二学期"
        let obj = {
          id: el.id,
          week: el.week_number,
          year: year,
          nper: nper,
          name: year + " / " + nper,
          is_current: el.is_current,
        }
        return obj
      })
    },
    // 获取下拉列表数据-学期
    async getSemester () {
      const condition = sessionStorage.getItem('semester_all')
      if (condition) {
        let data = JSON.parse(condition)
        this.formatSelect(data.data)
      } else {
        const params = { page: 1, per_page: 100 }
        await gettreelist(params).then((response) => {
          if (response.code === 0 && response.data) {
            this.formatSelect(response.data.data)
          }
        }).catch((error) => { })
      }
    },
    getcollegelist () {
      this.optionthree = []
      const condition = sessionStorage.getItem('college_all')
      if (condition) {
        this.optionthree = JSON.parse(condition)
      } else {
        const params = {
          record_type: 1
        }
        getcollegelist(params)
          .then((response) => {
            if (response.code === 0) {
              if (response.data && response.data.length > 0) {
                response.data.forEach((item) => {
                  this.optionthree.push({
                    id: item.id,
                    name: item.name,
                  })
                })
              }
            }
          })
          .catch((error) => { })
      }
    },
    getlablist () {
      getlablist(this.queryParams).then((response) => {
        if (response.code === 0) {
          if (response.data.data && response.data.data.length > 0) {
            this.hasMoreItems = response.data.data.length > 9 ? true : false
            response.data.data.forEach((item) => {
              this.optionfour.push({
                value: item.id,
                label: item.lab_name,
              })
            })
          }
        }
      })
        .catch((error) => { })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    width: 100%;
    overflow: hidden;
    margin-top: 100px;

    .maincontain {
      background: #ffffff;
      border-radius: 4px;
      // padding: 20px;

      .searchcontain {
        background: #fcfcfc;
        border: 1px solid #ececec;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // padding: 15px 10px;
        width: 100%;
        overflow: hidden;
        // padding-bottom: 30px;
        padding: 20px 20px 20px 10px;

        .left {
          // display: flex;
          // flex-wrap: wrap;
          width: 100%;
          height: auto;

          .searchitem {
            // width: 20%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // margin: 0 10px 10px;
            width: calc((100% - 50px) / 2);
            float: left;
            margin-left: 10px;

            .maintext {
              margin-right: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #888888;
              height: 35px;
              line-height: 35px;
            }

            .elinput {
              width: 100%;

              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right {
          // padding-bottom: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          float: right;
          margin-top: 20px;
          width: 47%;

          .bt {
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #3d84ff;
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
          }

          .restbt {
            color: #3d84ff;
          }

          .searchbt {
            margin-right: 10px;
            border: 1px solid #3d84ff;

            background: #3d84ff;
            color: white;
          }
        }
      }

      .main-contain {
        height: 100%;
        width: 100%;

        .contain {
          width: 100%;
          height: 100%;

          .table {
            width: 100%;

            .btgroup {
              .bt {
                border: none;
              }
            }

            .opreatbt {
              border: none;
              background: none;
              margin: 0px;
              padding: 0px;
              color: #3d84ff;
            }
          }
        }
      }

      .elpagination {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }

  .btgroup {
    color: #3d84ff;
    height: 60px;
    width: 100%;

    .bt {
      margin-top: 10px !important;
      width: 100%;
      height: 20px;
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .mainpage {
    width: 100%;
    overflow: hidden;
    margin-top: 100px;

    .maincontain {
      background: #ffffff;
      border-radius: 4px;
      // padding: 20px;

      .searchcontain {
        background: #fcfcfc;
        border: 1px solid #ececec;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // padding: 15px 10px;
        width: 100%;
        overflow: hidden;
        // padding-bottom: 30px;
        padding: 20px 20px 20px 10px;

        .left {
          // display: flex;
          // flex-wrap: wrap;
          width: 100%;
          height: auto;

          .searchitem {
            // width: 20%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // margin: 0 10px 10px;
            width: calc((100% - 50px) / 2);
            float: left;
            margin-left: 10px;

            .maintext {
              margin-right: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #888888;
              height: 35px;
              line-height: 35px;
            }

            .elinput {
              width: 100%;

              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right {
          // padding-bottom: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          float: right;
          margin-top: 20px;
          width: 47%;
          margin-right: 30px;

          .bt {
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #3d84ff;
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
          }

          .restbt {
            color: #3d84ff;
          }

          .searchbt {
            margin-right: 10px;
            border: 1px solid #3d84ff;

            background: #3d84ff;
            color: white;
          }
        }
      }

      .main-contain {
        height: 100%;
        width: 100%;

        .contain {
          width: 100%;
          height: 100%;

          .table {
            width: 100%;

            .btgroup {
              .bt {
                border: none;
              }
            }

            .opreatbt {
              border: none;
              background: none;
              margin: 0px;
              padding: 0px;
              color: #3d84ff;
            }
          }
        }
      }

      .elpagination {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }

  .btgroup {
    color: #3d84ff;
    height: 60px;
    width: 100%;

    .bt {
      margin-top: 10px !important;
      width: 100%;
      height: 20px;
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainpage {
    .maincontain {
      background: #ffffff;
      border-radius: 4px;
      padding: 20px;

      .searchcontain {
        background: #fcfcfc;
        border: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 10px;

        .left {
          display: flex;
          flex-wrap: wrap;

          .searchitem {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 10px 10px;

            .maintext {
              margin-right: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #888888;
            }

            .elinput {
              width: 200px;

              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right {
          padding-bottom: 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #3d84ff;
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
          }

          .restbt {
            color: #3d84ff;
          }

          .searchbt {
            margin-right: 10px;
            border: 1px solid #3d84ff;

            background: #3d84ff;
            color: white;
          }
        }
      }

      .main-contain {
        height: 100%;
        width: 100%;

        .contain {
          width: 100%;
          height: 100%;

          .table {
            width: 100%;

            .btgroup {
              .bt {
                border: none;
              }
            }

            .opreatbt {
              border: none;
              background: none;
              margin: 0px;
              padding: 0px;
              color: #3d84ff;
            }
          }
        }
      }

      .elpagination {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }

  .btgroup {
    color: #3d84ff;
    height: 60px;
    width: 100%;

    .bt {
      margin-top: 10px !important;
      width: 100%;
      height: 20px;
      margin: 0px;
      padding: 0px;
      border: none;
    }
  }
}
</style>
