import request from '@/utils/request'

//修改院系
export function editcollege(data) {
    return request({
        url: '/portal/college/edit',
        method: 'POST',
        data
    })
}

//删除院系
export function deletecollege(data) {
    return request({
        url: '/portal/college/delete',
        method: 'POST',
        data
    })
}

//新增院系
export function addcollege(data) {
    return request({
        url: '/portal/college/add',
        method: 'POST',
        data
    })
}

//获取院系列表
export function getcollegelist(params) {
    return request({
        url: '/portal/college/get-list',
        method: 'GET',
        params
    })
}
